import './App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';

import CompShowInicio from './components/inicio.js';
import CompShowLogin from './components/login';
import CompVenta from './components/venta';
import CompInventario from './components/inventario';
import CompCrearProducto from './components/crearProducto.js';
import CompCarrito from './components/carrito';
import CompCrearDescuento from './components/createDescuento';
import CompShowVentas from './components/showVentas';
import CajaManager from './components/caja/cajaManager';
import { SidebarProvider, useSidebar } from './components/sidebarContext';
import CompSidebar from './components/sideBar';
import HistorialCajas from './components/caja/historialCaja';
import IngresoStock from './components/ingreso.js';
import { FaBars } from 'react-icons/fa';
import CompGestionStockMenu from './components/menus/gestionStockMenu.js';
import { AuthProvider } from './context/AuthContext'; // Contexto para manejo de roles
import PrivateRoute from './components/privateRoute.js'; // Componente para validar roles
import CompVentasMenu from './components/menus/ventasMenu.js';
import CompCajaMenu from './components/menus/cajaMenu.js';
import Egreso from './components/egreso.js';
const ToggleButton = () => {
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  return (
    <div className="fixed top-4 left-4 z-50 flex items-center space-x-4 bg-gray-200 p-3 rounded-lg shadow-lg">
      <button
        onClick={toggleSidebar}
        className={`bg-indigo-600 text-white p-3 rounded-full shadow-md transition-transform duration-300 hover:bg-indigo-700 hover:scale-110 focus:outline-none focus:ring-2 focus:ring-indigo-400 ${isSidebarOpen ? 'rotate-90' : ''
          }`}
        aria-label={isSidebarOpen ? 'Cerrar Sidebar' : 'Abrir Sidebar'}
      >
        <FaBars size={20} />
      </button>

      <Link
        to="/"
        className="text-black font-semibold text-lg hover:text-indigo-400 transition-colors duration-200"
      >
        Inicio
      </Link>
    </div>

  );
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SidebarProvider>
          <div>
            <ToggleButton />
            <CompSidebar />
            <Routes>
              {/* Ruta pública */}
              <Route path="/" element={
                <PrivateRoute allowedRoles={['admin', 'seller']}>
                  <CompShowInicio />
                </PrivateRoute>
              } />


              <Route path="/login" element={<CompShowLogin />} />

              {/* Rutas protegidas */}

              <Route
                path="/inventario"
                element={
                  <PrivateRoute allowedRoles={['admin']}>
                    <CompInventario />
                  </PrivateRoute>
                }
              />
              <Route
                path="/caja/historial"
                element={
                  <PrivateRoute allowedRoles={['admin']}>
                    <HistorialCajas />
                  </PrivateRoute>
                }
              />
              <Route
                path="/caja"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seller']}>
                    <CajaManager />
                  </PrivateRoute>
                }
              />
              <Route
                path="/crear-producto"
                element={
                  <PrivateRoute allowedRoles={['admin']}>
                    <CompCrearProducto />
                  </PrivateRoute>
                }
              />
              <Route
                path="/venta/:tipo"
                element={
                  <PrivateRoute allowedRoles={['seller', 'admin']}>
                    <CompCarrito />
                  </PrivateRoute>
                }
              />

              <Route
                path="/ventas"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seller']}>
                    <CompShowVentas />
                  </PrivateRoute>
                }
              />
              <Route
                path="/gestion-stock"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seller']}>
                    <CompGestionStockMenu />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ventas-menu"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seller']}>
                    <CompVentasMenu />
                  </PrivateRoute>
                }
              />
              <Route
                path="/caja-menu"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seller']}>
                    <CompCajaMenu />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ingreso"
                element={
                  <PrivateRoute allowedRoles={['admin']}>
                    <IngresoStock />
                  </PrivateRoute>
                }
              />
              <Route
                path="/egreso"
                element={
                  <PrivateRoute allowedRoles={['admin', 'seler']}>
                    <Egreso />
                  </PrivateRoute>
                }
              />
              <Route
                path="/ofertas"
                element={
                  <PrivateRoute allowedRoles={['admin']}>
                    <CompCrearDescuento />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
        </SidebarProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
