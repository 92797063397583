import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  FaCashRegister,
  FaBoxes,
  FaTags,
  FaDollarSign,
} from "react-icons/fa";

const CompShowInicio = () => {


  return (
    <main className="flex-grow flex items-center justify-center mt-16">
      <div className="w-full max-w-4xl p-6">
        {/* Mostrar botones principales si no hay un menú activo */}
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-6"
        >
          {/* Ventas */}
          <Link
            to={"/ventas-menu"}
            className="w-full bg-gradient-to-r from-pink-500 to-red-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-red-600 hover:to-pink-600 transition transform hover:scale-105"
          >
            <FaDollarSign className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Ventas</h2>
          </Link>

          {/* Gestión de Stock */}
          <Link
            to="/gestion-stock"
            className="w-full bg-gradient-to-r from-blue-500 to-green-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-green-600 hover:to-blue-600 transition transform hover:scale-105"
          >
            <FaBoxes className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Gestión de Stock</h2>
          </Link>

          {/* Caja */}
          <Link
            to="/caja-menu"
            className="bg-gradient-to-r from-indigo-500 to-blue-500 shadow-lg rounded-lg p-6 text-center text-white hover:from-blue-600 hover:to-indigo-600 transition transform hover:scale-105"
          >
            <FaCashRegister className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Caja</h2>
          </Link>

          {/* Ofertas */}
          <Link
            to="/ofertas"
            className="bg-gradient-to-r from-red-400 to-pink-400 shadow-lg rounded-lg p-6 text-center text-white hover:from-pink-500 hover:to-red-500 transition transform hover:scale-105"
          >
            <FaTags className="text-4xl mx-auto mb-2" />
            <h2 className="text-2xl font-semibold mb-2">Ofertas</h2>
          </Link>
        </div>


      </div>
    </main>
  );
};

export default CompShowInicio;
