import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { 
  FaStore, 
  FaCartPlus, 
  FaListAlt, 
  FaPlusCircle, 
  FaClipboardList, 
  FaChevronDown 
} from "react-icons/fa";

const CompGestionStockMenu = () => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => setShowOptions((prev) => !prev);

  return (
    <div className="flex-grow flex items-center justify-center mt-16">
      <div className="w-full max-w-4xl p-6">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="space-y-6"
        >
          {/* Título */}
          <div className="flex items-center justify-center mb-6">
            <h2 className="text-4xl font-bold text-center text-gray-700">
              Gestión de Stock
            </h2>
          </div>

          {/* Opción: Inventario */}
          <Link
            to="/inventario"
            className="block bg-indigo-500 rounded-lg py-4 px-6 text-white text-center shadow-md hover:bg-indigo-600 transition"
          >
            <div className="flex items-center justify-center">
              <FaStore className="text-2xl mr-3" />
              <span className="text-lg font-semibold">Inventario</span>
            </div>
          </Link>

          {/* Botón principal para ingresos */}
          <div className="relative">
            <button
              onClick={toggleOptions}
              className=" w-full bg-blue-500  rounded-lg py-4 px-6 text-white text-center shadow-md hover:bg-blue-600 transition flex items-center justify-center"
            >
              <FaCartPlus className="text-2xl mr-3" />
              <span className="text-lg font-semibold">Ingresos</span>
              <FaChevronDown
                className={`ml-3 text-xl transform transition-transform ${
                  showOptions ? "rotate-180" : ""
                }`}
              />
            </button>

            {/* Opciones adicionales animadas */}
            <AnimatePresence>
              {showOptions && (
                <motion.div
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className="overflow-hidden mt-2 space-y-2"
                >
                  <Link
                    to="/ingreso"
                    className=" bg-blue-400 rounded-lg py-3 px-5 text-white text-center shadow-md hover:bg-blue-500 transition flex items-center justify-center"
                  >
                    <FaPlusCircle className="text-lg mr-2" />
                    <span className="text-sm font-semibold">Agregar al stock</span>
                  </Link>
                  <Link
                    to="/crear-producto"
                    className=" bg-blue-400  rounded-lg py-3 px-5 text-white text-center shadow-md hover:bg-blue-500 transition flex items-center justify-center"
                  >
                    <FaClipboardList className="text-lg mr-2" />
                    <span className="text-sm font-semibold">Crear un producto</span>
                  </Link>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          {/* Opción: Egresos */}
          <Link
            to="/egreso"
            className="block bg-orange-500 rounded-lg py-4 px-6 text-white text-center shadow-md hover:bg-orange-600 transition"
          >
            <div className="flex items-center justify-center">
              <FaListAlt className="text-2xl mr-3" />
              <span className="text-lg font-semibold">Egresos</span>
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default CompGestionStockMenu;
