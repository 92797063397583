import { useState, useEffect } from 'react';
import axios from 'axios';
import CompCreateProv from './createProv';
import { FaPlus } from 'react-icons/fa';

import { useAuth } from '../context/AuthContext';  // Asegúrate de importar el contexto
const IngresoStock = () => {
    const [productos, setProductos] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const { token } = useAuth();
    const caja = localStorage.getItem('cajaAbiertaId');

    const [searchTerm, setSearchTerm] = useState('');
    const [form, setForm] = useState({
        producto_id: '',
        cantidad_unidades: '',
        proveedor_id: '',
        precio: '',
        precio_compra: '',
        precio_mayorista_a: '',
        precio_mayorista_b: '',
        precio_mayorista_c: '',
        forma_pago: '',
    });
    const [filteredProductos, setFilteredProductos] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [open, setOpen] = useState(false);

    // Cargar datos de productos y proveedores
    const fetchData = async () => {
        try {
            const [responseProductos, responseProveedores] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`, {
                    headers: {
                        'x-auth-token': token,
                    },
                }),
                axios.get(`${process.env.REACT_APP_API_URL}/proveedores`, {
                    headers: {
                        'x-auth-token': token,
                    },
                }),
            ]);

            setProductos(responseProductos.data.productos);
            setProveedores(responseProveedores.data.proveedores);
        } catch (error) {
            console.error('Error al cargar datos:', error);
            setError('No se pudo cargar la lista de productos o proveedores.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const results = productos.filter((producto) =>
            producto.nombre_producto.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProductos(results);
    }, [searchTerm, productos]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const resetForm = () => {
        setForm({
            producto_id: '',
            cantidad_unidades: '',
            proveedor_id: '',
            precio: '',
            precio_compra: '',
            precio_mayorista_a: '',
            precio_mayorista_b: '',
            precio_mayorista_c: '',
            forma_pago: '',
        });
        setSearchTerm('');
    };

    const handleSubmit = async (e) => {
        if (!caja) {
            alert('No puedes realizar ingresos sin abrir una caja');
            return
        }
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/ingresos/`, form, {
                headers: {
                    'x-auth-token': token,
                },
            });

            setSuccessMessage('Stock ingresado con éxito');
            resetForm();
            fetchData(); // Recargar datos para obtener la última información
        } catch (error) {
            console.error('Error al registrar el ingreso de stock:', error);
            setError('Error al registrar el ingreso de stock. Verifica los datos ingresados.');
        }
    };

    const handleSelectProducto = (productoId, productoName) => {
        if (!caja) {
            alert('No puedes realizar ingresos sin abrir una caja');
            return
        }
        const productoSeleccionado = productos.find((producto) => producto.id === productoId);

        setForm({
            ...form,
            producto_id: productoId,
            proveedor_id: '', // Limpia el proveedor seleccionado
            precio: productoSeleccionado.precios[0]?.precio || '',
            precio_compra: productoSeleccionado.precios[0]?.precio_compra || '',
            precio_mayorista_a: productoSeleccionado.precios[0]?.precio_mayorista_a || '',
            precio_mayorista_b: productoSeleccionado.precios[0]?.precio_mayorista_b || '',
            precio_mayorista_c: productoSeleccionado.precios[0]?.precio_mayorista_c || '',
        });

        setSearchTerm(productoName);
        setFilteredProductos([]); // Limpia la lista de sugerencias tras seleccionar un producto
    };

    const handleOpenCreateProv = () => setOpen(true);

    const onClose = () => setOpen(false);

    const onCreateProveedor = async () => {
        try {
            const responseProveedores = await axios.get(`${process.env.REACT_APP_API_URL}/proveedores`, {
                headers: {
                    'x-auth-token': token,
                },
            });
            setProveedores(responseProveedores.data.proveedores);
        } catch (error) {
            console.error('Error al actualizar lista de proveedores:', error);
        }
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-4xl font-bold text-center mb-6">Ingreso de Stock</h2>

            {error && (
                <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
                    {error}
                </div>
            )}

            {successMessage && (
                <div className="bg-green-100 text-green-700 p-4 rounded-md mb-4">
                    {successMessage}
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Buscador de Productos */}
                <div>
                    <label htmlFor="search" className="block font-medium text-gray-700">Buscar Producto:</label>
                    <input
                        type="text"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Escribe el nombre del producto..."
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    {/* Lista de sugerencias */}
                    {searchTerm && filteredProductos.length > 0 && (
                        <ul className="mt-2 border border-gray-300 rounded-md max-h-40 overflow-y-auto bg-white shadow-md">
                            {filteredProductos.map((producto) => (
                                <li
                                    key={producto.id}
                                    onClick={() => handleSelectProducto(producto.id, producto.nombre_producto)}
                                    className="p-2 hover:bg-indigo-100 cursor-pointer"
                                >
                                    <div className='flex justify-between'>
                                        <p>{producto.nombre_producto}</p>
                                        <p className={producto.stock_unidades <= 0 ? 'text-red-600' : 'text-green-600'}>
                                            Stock: {producto.stock_unidades}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div>
                    <label htmlFor="cantidad_unidades" className="block font-medium text-gray-700">Cantidad de Unidades:</label>
                    <input
                        type="number"
                        id="cantidad_unidades"
                        name="cantidad_unidades"
                        value={form.cantidad_unidades}
                        onChange={handleChange}
                        min="1"
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Proveedor */}
                <div className="mb-4">
                    <label htmlFor="proveedores" className="block font-medium text-gray-700 mb-2">
                        Proveedor:
                    </label>
                    <div className="flex items-center space-x-2">
                        <select
                            required
                            name="proveedor_id"
                            value={form.proveedor_id}
                            onChange={handleChange}
                            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="" disabled >Seleccione un proveedor</option>
                            {proveedores.map((proveedor) => (
                                <option key={proveedor.id} value={proveedor.id}>
                                    {proveedor.nombre}
                                </option>
                            ))}
                        </select>
                        <button
                            type="button"
                            onClick={handleOpenCreateProv}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            <FaPlus />
                        </button>
                    </div>
                </div>
                {/* Campos de Precios */}
                <div>
                    <label htmlFor="precio" className="block font-medium text-gray-700">Precio de Venta:</label>
                    <input
                        type="number"
                        id="precio"
                        name="precio"
                        value={form.precio}
                        onChange={handleChange}
                        step="0.01"
                        min="0.01"
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <label htmlFor="precio_compra" className="block font-medium text-gray-700">Precio de Compra:</label>
                    <input
                        type="number"
                        id="precio_compra"
                        name="precio_compra"
                        value={form.precio_compra}
                        onChange={handleChange}
                        step="0.01"
                        min="0.01"
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <label htmlFor="precio_mayorista_a" className="block font-medium text-gray-700">Precio Mayorista A:</label>
                    <input
                        type="number"
                        id="precio_mayorista_a"
                        name="precio_mayorista_a"
                        value={form.precio_mayorista_a}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <label htmlFor="precio_mayorista_b" className="block font-medium text-gray-700">Precio Mayorista B:</label>
                    <input
                        type="number"
                        id="precio_mayorista_b"
                        name="precio_mayorista_b"
                        value={form.precio_mayorista_b}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <label htmlFor="precio_mayorista_c" className="block font-medium text-gray-700">Precio Mayorista C:</label>
                    <input
                        type="number"
                        id="precio_mayorista_c"
                        name="precio_mayorista_c"
                        value={form.precio_mayorista_c}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                <div>
                    <label htmlFor="forma_pago" className="block font-medium text-gray-700">Forma de pago:</label>
                    <select
                        id="forma_pago"
                        name="forma_pago"
                        value={form.forma_pago}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <option value="" disabled>Seleccionar forma de pago</option>
                        <option value="efectivo">Efectivo</option>
                        <option value="transferencia">Transferencia</option>
                        <option value="tarjeta_credito">Crédito</option>
                        <option value="tarjeta_debito">Débito</option>
                        <option value="otros">Otros</option>
                    </select>
                </div>

                {/* Botón de Submit */}
                <div>
                    <button
                        type="submit"
                        className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        Registrar Ingreso
                    </button>
                </div>
            </form >

            {/* Modal para crear proveedor */}
            {
                open && (
                    <CompCreateProv open={open} onClose={onClose} onCreateProveedor={onCreateProveedor} />
                )
            }
        </div >
    );
};

export default IngresoStock;