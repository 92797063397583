import React, { useState, useEffect, useRef } from 'react';
import CompEditProduct from './EditProducto';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

const CompInventario = () => {
  const [inventory, setInventory] = useState([]);
  const { token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [orderDirection, setOrderDirection] = useState("asc");
  const [searchTerm, setSearchTerm] = useState(""); // Nuevo estado para el término de búsqueda
  const [searchBy, setSearchBy] = useState("codigo_barras"); // Nuevo estado para el tipo de búsqueda

  const editProductRef = useRef(null);

  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/admin`,
          {
            params: { orderBy, orderDirection, searchTerm, searchBy }, // Parámetros dinámicos
            headers: {
              'x-auth-token': token,
            },
          }
        );

        setInventory(response.data.productos);
      } catch (error) {
        setError('Error al obtener los productos');
      } finally {
        setLoading(false);
      }
    };

    fetchInventory();
  }, [orderBy, orderDirection, searchTerm, searchBy]);

  const handleEditProduct = (productId) => {
    setSelectedProductId(productId);
    setTimeout(() => {
      editProductRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  };

  const reloadInventory = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/admin`,
        {
          params: { orderBy, orderDirection },
          headers: {
            'x-auth-token': token,
          },
        }
      );
      setInventory(response.data.productos);
    } catch (error) {
      setError('Error al obtener los productos');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseEdit = () => {
    setSelectedProductId(null);
  };

  return (
    <div className='text-center flex flex-col align-middle items-center p-3'>
      <div className="flex flex-col space-y-4 p-5 bg-gray-50 rounded-lg shadow-md border border-gray-200">
        <h1 className="text-primary">Gestión de Inventario</h1>
        <div className="grid grid-cols-1 py-5 md:grid-cols-4 gap-4">
          <div className="flex flex-col">
            <label htmlFor="searchBy" className="mb-1 text-sm font-medium text-gray-600">
              Buscar por:
            </label>
            <select
              id="searchBy"
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            >
              <option value="codigo_barras">Plug asociado</option>
              <option value="nombre_producto">Nombre del Producto</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="searchTerm" className="mb-1 text-sm font-medium text-gray-600">
              Término de búsqueda:
            </label>
            <input
              id="searchTerm"
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Ej: ref123 o nombre"
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="orderBy" className="mb-1 text-sm font-medium text-gray-600">
              Ordenar por:
            </label>
            <select
              id="orderBy"
              value={orderBy}
              onChange={(e) => setOrderBy(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            >
              <option value="createdAt">Fecha de creación</option>
              <option value="updatedAt">Última actualización</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label htmlFor="orderDirection" className="mb-1 text-sm font-medium text-gray-600">
              Dirección:
            </label>
            <select
              id="orderDirection"
              value={orderDirection}
              onChange={(e) => setOrderDirection(e.target.value)}
              className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
            >
              <option value="asc">Ascendente</option>
              <option value="desc">Descendente</option>
            </select>
          </div>
        </div>
      </div>

      <div className="flex items-start justify-center min-h-screen bg-gray-100 p-4">
        <div className="bg-white shadow-lg rounded-lg w-full max-w-6xl flex flex-col lg:flex-row">
          <div className={`w-full ${selectedProductId ? 'lg:w-2/5' : 'w-full'} p-8`}>
            <h2 className="text-xl font-semibold mb-4">Inventario Actual</h2>

            {loading && <p className="text-center text-gray-500">Cargando inventario...</p>}
            {error && <p className="text-center text-red-500">Error: {error}</p>}

            {!loading && !error && inventory.length > 0 ? (
              <ul className="space-y-4">
                {inventory.map((product) => (
                  <li
                    key={product.id}
                    className="border p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
                  >
                    <h3 className="text-lg font-bold">{product.nombre_producto}</h3>
                    <p className="text-sm text-gray-600">Plug asociado: {product.codigo_barras?.map(bar => bar.codigo).join(', ') || 'N/A'}</p>
                    <p className="text-sm text-gray-600">Stock: {product.stock_unidades}</p>
                    <button
                      onClick={() => handleEditProduct(product.id)}
                      className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                      Editar
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              !loading && <p className="text-center text-gray-500">No hay productos en el inventario.</p>
            )}
          </div>

          {selectedProductId && (
            <div
              ref={editProductRef}
              className="bg-gray-50 border-l border-gray-300 px-4 h-full max-h-screen overflow-y-auto lg:w-3/5"
            >
              <CompEditProduct
                productId={selectedProductId}
                onSave={reloadInventory}
                handleCloseEdit={handleCloseEdit}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompInventario;
