import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';  // Asegúrate de importar el contexto

const Egreso = () => {
    const [productos, setProductos] = useState([]);
    const { token } = useAuth();

    const [searchTerm, setSearchTerm] = useState('');
    const [form, setForm] = useState({
        producto_id: '',
        cantidad: '',
        motivo: '', // Agregar motivo
        descripcion: '', // Descripción opcional
    });
    const [filteredProductos, setFilteredProductos] = useState([]);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Cargar datos de productos
    const fetchData = async () => {
        try {
            const responseProductos = await axios.get(`${process.env.REACT_APP_API_URL}/productos/admin`, {
                headers: {
                    'x-auth-token': token,
                },
            });

            setProductos(responseProductos.data.productos);
        } catch (error) {
            console.error('Error al cargar datos:', error);
            setError('No se pudo cargar la lista de productos.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const results = productos.filter((producto) =>
            producto.nombre_producto.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredProductos(results);
    }, [searchTerm, productos]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        });
    };

    const resetForm = () => {
        setForm({
            producto_id: '',
            cantidad: '',
            motivo: '',
            descripcion: '',
        });
        setSearchTerm('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');
        setLoading(true);

        try {
            // Verificar si la cantidad a descontar es válida
            const productoSeleccionado = productos.find((producto) => producto.id === form.producto_id);
            if (productoSeleccionado && form.cantidad > productoSeleccionado.stock_unidades) {
                setError('La cantidad a descontar no puede ser mayor al stock disponible.');
                setLoading(false);
                return;
            }

            // Enviar la solicitud para registrar el egreso
            await axios.post(`${process.env.REACT_APP_API_URL}/egresos/`, form, {
                headers: {
                    'x-auth-token': token,
                },
            });

            setSuccessMessage('Egreso registrado con éxito');
            resetForm();
            fetchData(); // Recargar datos para obtener la última información
        } catch (error) {
            console.error('Error al registrar el egreso:', error);
            setError('Error al registrar el egreso. Verifica los datos ingresados.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectProducto = (productoId, productoName) => {
        const productoSeleccionado = productos.find((producto) => producto.id === productoId);
        setForm({
            ...form,
            producto_id: productoId,
        });
        setSearchTerm(productoName);
        setFilteredProductos([]); // Limpia la lista de sugerencias tras seleccionar un producto
    };

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
            <h2 className="text-4xl font-bold text-center mb-6">Registrar Egreso</h2>

            {error && (
                <div className="bg-red-100 text-red-700 p-4 rounded-md mb-4">
                    {error}
                </div>
            )}

            {successMessage && (
                <div className="bg-green-100 text-green-700 p-4 rounded-md mb-4">
                    {successMessage}
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Buscador de Productos */}
                <div>
                    <label htmlFor="search" className="block font-medium text-gray-700">Buscar Producto:</label>
                    <input
                        type="text"
                        id="search"
                        name="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Escribe el nombre del producto..."
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                    {/* Lista de sugerencias */}
                    {searchTerm && filteredProductos.length > 0 && (
                        <ul className="mt-2 border border-gray-300 rounded-md max-h-40 overflow-y-auto bg-white shadow-md">
                            {filteredProductos.map((producto) => (
                                <li
                                    key={producto.id}
                                    onClick={() => handleSelectProducto(producto.id, producto.nombre_producto)}
                                    className="p-2 hover:bg-indigo-100 cursor-pointer"
                                >
                                    <div className='flex justify-between'>
                                        <p>{producto.nombre_producto}</p>
                                        <p className={producto.stock_unidades <= 0 ? 'text-red-600' : 'text-green-600'}>
                                            Stock: {producto.stock_unidades}
                                        </p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                {/* Motivo del Egreso */}
                <div>
                    <label htmlFor="motivo" className="block font-medium text-gray-700">Motivo del Egreso:</label>
                    <select
                        id="motivo"
                        name="motivo"
                        value={form.motivo}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        <option value="">Selecciona un motivo</option>
                        <option value="devolucion">Devolución</option>
                        <option value="rotura">Rotura</option>
                        <option value="vencimiento">Vencimiento</option>
                        <option value="otro">Otro</option>
                    </select>
                </div>

                {/* Cantidad de Unidades */}
                <div>
                    <label htmlFor="cantidad" className="block font-medium text-gray-700">Cantidad de Unidades:</label>
                    <input
                        type="number"
                        id="cantidad"
                        name="cantidad"
                        value={form.cantidad}
                        onChange={handleChange}
                        min="1"
                        required
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Descripción */}
                <div>
                    <label htmlFor="descripcion" className="block font-medium text-gray-700">Descripción (opcional):</label>
                    <textarea
                        id="descripcion"
                        name="descripcion"
                        value={form.descripcion}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    />
                </div>

                {/* Botón de Enviar */}
                <div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    >
                        {loading ? 'Cargando...' : 'Registrar Egreso'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Egreso;
