import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token') || null); // Maneja el token
    const [userRole, setUserRole] = useState(null); // "admin", "vendedor", etc.
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchUserRole = async () => {
            try {
                if (!token) {
                    setUserRole(null);
                    setLoading(false);
                    return;
                }

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/role`, {
                    headers: { 'x-auth-token': token },
                });

                setUserRole(response.data.role); // Ejemplo: "admin", "vendedor"
            } catch (error) {
                console.error('Error fetching role:', error);
                setUserRole(null);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRole();
    }, [token]); // Este efecto se ejecuta cuando el token cambia


    // Función para iniciar sesión
    const login = (newToken) => {
        setToken(newToken);
        localStorage.setItem('token', newToken); // Guardar el token en localStorage
    };

    // Función para cerrar sesión
    const logout = () => {
        setToken(null);
        setUserRole(null);
        localStorage.removeItem('token'); // Eliminar el token de localStorage
    };

    return (
        <AuthContext.Provider value={{ token, userRole, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);