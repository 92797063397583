import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../context/AuthContext'; // Importa el contexto de autenticación

const HistorialCajas = () => {
  const { token } = useAuth(); // Obtener el token desde el contexto
  const [cajas, setCajas] = useState([]);
  const [cajaSeleccionada, setCajaSeleccionada] = useState(null);
  const [movimientos, setMovimientos] = useState([]);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [saldoTotal, setSaldoTotal] = useState(0);

  useEffect(() => {
    const obtenerCajas = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/allCajas`, {
          headers: {
            'x-auth-token': token, // Agregar el token a los headers
          },
        });
        setCajas(response.data);
      } catch (error) {
        console.error('Error al obtener las cajas:', error);
      }
    };
    obtenerCajas();
  }, [token]);

  useEffect(() => {
    calcularSaldoTotal(); // Calcular saldo total cada vez que cambien las cajas
  }, [cajas]);

  const calcularSaldoTotal = () => {
    const total = cajas.reduce((acc, caja) => {
      const saldoFinal = parseFloat(caja.saldo_final);

      return !isNaN(saldoFinal) ? acc + saldoFinal : acc; // Verifica que sea un número válido
    }, 0);
    setSaldoTotal(total);
  };


  const verMovimientos = async (cajaId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/${cajaId}`, {
        headers: {
          'x-auth-token': token, // Agregar el token a los headers
        },
      });
      setCajaSeleccionada(response.data);
      setMovimientos(response.data.movimientos); // Suponiendo que la respuesta incluye movimientos
    } catch (error) {
      console.error('Error al obtener los movimientos de la caja:', error);
    }
  };

  const filtrarCajas = () => {
    return cajas.filter((caja) => {
      const fechaCaja = new Date(caja.fecha_apertura); // Asumiendo que hay un campo 'fecha_apertura' en 'caja'
      return (
        (!fechaInicio || fechaCaja >= new Date(fechaInicio)) &&
        (!fechaFin || fechaCaja <= new Date(fechaFin))
      );
    });
  };

  const limpiarFiltros = () => {
    setFechaInicio('');
    setFechaFin('');
  };
  const cajasFiltradas = filtrarCajas();

  // Actualizar el saldo total cada vez que cambien las cajas filtradas
  useEffect(() => {
    const totalFiltrado = cajasFiltradas.reduce((acc, caja) => {
      const saldoFinal = parseFloat(caja.saldo_final);
      return !isNaN(saldoFinal) ? acc + saldoFinal : acc;
    }, 0);
    setSaldoTotal(totalFiltrado);
  }, [cajasFiltradas]);



  const formatoMonetario = (cantidad) => {
    return cantidad.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, // Asegúrate de que siempre tenga 2 decimales
    });
  };

  return (
    <div className='mt-6'>
      <h2 className="text-primary text-center mb-6">Historial de Cajas</h2>
      <div className="container mx-auto p-6 grid grid-cols-1 lg:grid-cols-3 gap-4 relative">
        {/* Lista de Cajas */}
        <div className="col-span-1 lg:col-span-2">
          <ul className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {cajasFiltradas.map((caja) => (
              <motion.li
                key={caja.id}
                className="p-4 bg-white shadow-md rounded-lg flex justify-between items-center"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <div>
                  <p className="font-semibold text-lg">
                    Fecha: {new Date(caja.fecha_apertura).toLocaleDateString('es-AR', {
                      weekday: 'long', // Día de la semana
                      year: 'numeric', // Año
                      month: 'long', // Mes
                      day: 'numeric', // Día del mes
                    })}
                  </p>
                  <p className="text-gray-600">Saldo Inicial: ${caja.saldo_inicial}</p>
                  <p className="text-gray-600">Saldo Final: ${caja.saldo_final}</p>
                </div>
                <button
                  onClick={() => verMovimientos(caja.id)}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  Ver Movimientos
                </button>
              </motion.li>
            ))}
          </ul>
        </div>

        {/* Filtros */}
        <div className="col-span-1 lg:col-span-1 bg-white shadow-md rounded-lg p-4 mb-6">
          <h2 className="text-xl font-bold mb-4">Filtrar por Fecha</h2>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium text-gray-700 mb-2">Fecha Inicio</label>
            <input
              type="date"
              value={fechaInicio}
              onChange={(e) => setFechaInicio(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <div className="flex flex-col mb-4">
            <label className="text-sm font-medium text-gray-700 mb-2">Fecha Fin</label>
            <input
              type="date"
              value={fechaFin}
              onChange={(e) => setFechaFin(e.target.value)}
              className="p-2 border rounded"
            />
          </div>
          <button
            onClick={limpiarFiltros}
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition mb-4"
          >
            Limpiar Filtros
          </button>
          <h3 className="font-semibold text-lg">Saldo Total: {formatoMonetario(saldoTotal)}</h3>
        </div>

        {/* Sidebar para los detalles de la caja seleccionada */}
        <AnimatePresence>
          {cajaSeleccionada && (
            <motion.div
              key={cajaSeleccionada.id}
              className="fixed top-0 right-0 h-full w-full lg:w-1/3 bg-gray-100 p-6 rounded-lg shadow-lg overflow-y-auto"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ type: 'spring', stiffness: 80, damping: 20 }}
            >
              <button
                className="text-red-500 hover:text-red-700 mb-4"
                onClick={() => setCajaSeleccionada(null)}
              >
                Cerrar Detalles
              </button>

              <h3 className="text-2xl font-semibold mb-4">Detalles de la Caja {cajaSeleccionada.id}</h3>
              <h4 className="text-lg font-medium mb-2">Movimientos:</h4>
              <ul className="space-y-2">
                {movimientos.map((movimiento) => (
                  <motion.li
                    key={movimiento.id}
                    className="bg-white p-4 rounded-lg shadow-sm"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <p>
                      <span className="font-bold">{movimiento.tipo_movimiento}:</span> ${movimiento.monto}
                    </p>
                    <p className="text-gray-600">{movimiento.concepto}</p>
                  </motion.li>
                ))}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HistorialCajas;
