import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../context/AuthContext'; // Importa el contexto de autenticación
import axios from "axios";

const CompShowLogin = () => {
    const [email_user, setEmail] = useState('');
    const [password_user, setPassword] = useState('');
    const [error, setError] = useState(''); 
    const navigate = useNavigate();
    const { login } = useAuth(); // Obtén la función login del contexto

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Limpia errores previos

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                email_user,
                password_user,
            });

            if (response.status === 200) {
                // Guardar el token en localStorage y actualizar el estado global
                login(response.data.token);

                // Redirigir al dashboard o la página correspondiente
                navigate('/'); // O dependiendo del rol, a '/venta' o '/inventario'
            }
        } catch (error) {
            console.error(error);
            setError('Credenciales incorrectas o error en el servidor.');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
                <h1 className="text-2xl font-bold mb-6 text-center">Iniciar Sesión</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Correo Electrónico
                        </label>
                        <input
                            type="email"
                            id="email"
                            value={email_user}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Contraseña
                        </label>
                        <input
                            type="password"
                            id="password"
                            value={password_user}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    {error && (
                        <div className="mb-4 text-red-500 text-sm">
                            {error}
                        </div>
                    )}
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Iniciar Sesión
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CompShowLogin;
