import React, { useState, useEffect } from 'react';
import AperturaCaja from './aperturaCaja';
import RegistroMovimiento from './registroMovimiento';
import axios from 'axios';
import CierreCaja from './cierreCaja.js';
import { useAuth } from '../../context/AuthContext'; // Importar el contexto de autenticación

const CajaManager = () => {
  const [cajaAbierta, setCajaAbierta] = useState(null);
  const [saldoFinalCalculado, setSaldoFinalCalculado] = useState(0);
  const [saldoFinalEfectivo, setSaldoFinalEfectivo] = useState(0);
  const { token } = useAuth(); // Obtener el token desde el contexto

  const cajaIdGuardada = localStorage.getItem('cajaAbiertaId');


  const obtenerSaldosEfectivo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/efectivo/${cajaIdGuardada}`, {
        headers: { 'x-auth-token': token }, // Incluir el token en los headers
      });
      if (response.data) {
        setSaldoFinalEfectivo(response.data.saldo_final || 0); // Usar saldo_final en lugar de saldo_inicial
      }
    } catch (error) {
      console.error('Error al obtener la caja:', error);
    }
  };

  useEffect(() => {
    if (cajaIdGuardada) {

      const obtenerCajaAbierta = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/caja/${cajaIdGuardada}`, {
            headers: { 'x-auth-token': token }, // Incluir el token en los headers
          });
          if (response.data) {
            setCajaAbierta(response.data);
            setSaldoFinalCalculado(response.data.saldo_final || 0); // Usar saldo_final en lugar de saldo_inicial
          }
        } catch (error) {
          console.error('Error al obtener la caja:', error);
        }
      };
      obtenerSaldosEfectivo();
      obtenerCajaAbierta();
    }
  }, [token]); // El efecto se ejecutará nuevamente si el token cambia

  useEffect(() => {
    obtenerSaldosEfectivo();

  }, [saldoFinalCalculado])
  useEffect(() => {
    if (cajaAbierta) {
      localStorage.setItem('cajaAbiertaId', cajaAbierta.id);
    }
  }, [cajaAbierta]);

  const manejarMovimiento = (nuevoMovimiento) => {
    setSaldoFinalCalculado(nuevoMovimiento.saldoFinal);
  };

  const manejarCerrarCaja = () => {
    localStorage.removeItem('cajaAbiertaId');
    setCajaAbierta(null);
    setSaldoFinalCalculado(0);
  };

  const manejarCajaAbierta = (cajaData) => {
    setCajaAbierta(cajaData);
    setSaldoFinalCalculado(cajaData.saldo_final); // Usar saldo_final al abrir la caja
  };

  return (
    <div className="container mx-auto p-6">
      {!cajaAbierta ? (
        <AperturaCaja onCajaAbierta={manejarCajaAbierta} />
      ) : (
        <>
          <RegistroMovimiento cajaId={cajaAbierta.id} manejarMovimiento={manejarMovimiento} />
          <CierreCaja cajaId={cajaAbierta.id} saldoFinalCalculado={saldoFinalCalculado} saldoFinalEfectivo={saldoFinalEfectivo} onCerrarCaja={manejarCerrarCaja} />
        </>
      )}
    </div>
  );
};

export default CajaManager;
