import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';  // Importa el contexto

const AperturaCaja = ({ onCajaAbierta }) => {
  const [saldoInicial, setSaldoInicial] = useState('');
  const [tipoCaja, setTipoCaja] = useState('fisica');
  const [error, setError] = useState('');
  const { token } = useAuth();  // Obtener el token del contexto

  const abrirCaja = async () => {
    if (!saldoInicial || isNaN(saldoInicial)) {
      setError('Ingrese un saldo inicial válido.');
      return;
    }

    try {
      // Se agrega el token en los headers de la solicitud
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/caja/abrir`, {
        saldo_inicial: parseFloat(saldoInicial),
        tipo: tipoCaja,
      }, {
        headers: {
          'x-auth-token': token,  // Se manda el token en los headers
        }
      });

      onCajaAbierta(response.data); // Notifica al componente padre que la caja ha sido abierta
      setError('');  // Limpia el error si todo fue exitoso
    } catch (error) {
      setError('Error al abrir la caja. Intente nuevamente.');
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-primary text-center mb-4">Apertura de Caja</h2>

      {error && <p className="text-red-500">{error}</p>}

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Saldo Inicial
        </label>
        <input
          type="number"
          value={saldoInicial}
          onChange={(e) => setSaldoInicial(e.target.value)}
          className="w-full p-2 border rounded"
          placeholder="Ingrese saldo inicial"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Tipo de Caja
        </label>
        <input
          type='text'
          disabled
          value={tipoCaja}
          className="w-full p-2 border rounded"
        >
        </input>
      </div>

      <button
        onClick={abrirCaja}
        className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
      >
        Abrir Caja
      </button>
    </div>
  );
};

export default AperturaCaja;
