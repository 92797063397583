import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useParams } from "react-router-dom";

const CompCarrito = () => {
  const [cart, setCart] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedWholesalePrice, setSelectedWholesalePrice] = useState('precio_mayorista_a');
  const { token } = useAuth();
  const { tipo } = useParams();
  const [searchBy, setSearchBy] = useState("codigo_barras");
  const [paymentMethod, setPaymentMethod] = useState('efectivo'); // Predeterminado: efectivo
  const caja = localStorage.getItem('cajaAbiertaId');
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/admin`,
          {
            headers: { 'x-auth-token': token },
            params: {
              searchBy: searchBy, // 'nombre' o 'codigo_barras'
              searchTerm: searchTerm, // Término de búsqueda
            },
          }
        );
        setProductsData(response.data.productos);
        setFilteredProducts(response.data.productos); // Actualiza los productos filtrados con la respuesta
        console.log(response.data.productos);

      } catch (error) {
        console.error('Error al cargar productos:', error);
      }
    };

    if (searchTerm) {
      fetchProducts();
    } else {
      setProductsData([]); // Si no hay término de búsqueda, vaciar la lista de productos
      setFilteredProducts([]); // Vaciar los productos filtrados
    }
  }, [searchTerm, searchBy, token]);

  // Función para manejar la búsqueda por Plug asociado (cuando se presiona Enter)
  const handleSearchKeyDown = async (e) => {
    if (e.key === 'Enter' && searchTerm) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/admin`,
          {
            headers: { 'x-auth-token': token },
            params: {
              searchBy: 'codigo_barras',  // Forzar la búsqueda por Plug asociado
              searchTerm: searchTerm, // Plug asociado ingresado
            },
          }
        );

        const results = response.data.productos;
        setFilteredProducts(results);

        // Si hay exactamente un producto, añadirlo al carrito automáticamente
        if (results.length === 1) {
          addToCart(results[0]);
        }

      } catch (error) {
        console.error('Error al buscar productos:', error);
      }
    }
  };
  const addToCart = (product) => {
    if (!caja) {
      alert('No puedes realizar ventas sin abrir una caja');
      return
    }
    setCart((prevCart) => {
      const existingProduct = prevCart.find((p) => p.id === product.id);
      if (existingProduct) {
        if (existingProduct.quantity < product.stock_unidades) {
          return prevCart.map((p) =>
            p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
          );
        } else {
          alert('No puedes agregar más unidades de las disponibles en stock.');
        }
      } else {
        if (product.stock_unidades > 0) {
          return [...prevCart, { ...product, quantity: 1 }];
        } else {
          alert('Este producto está fuera de stock.');
        }
      }
      return prevCart;
    });
  };

  const getPrice = (product) => {
    if (tipo === 'mayorista') {
      return product.precios[0][selectedWholesalePrice] || product.precios[0].precio;
    }
    return product.precios?.[0]?.precio || 'N/A';
  };

  const isDiscounted = (product) => product.tieneDescuento;
  const getDiscountedPrice = (product) => {
    if (isDiscounted(product)) {
      return parseFloat(product.precioFinal.toFixed(2));
    }
    return getPrice(product);
  };

  const handleWholesalePriceChange = (e) => {
    setSelectedWholesalePrice(e.target.value);
  };



  const removeFromCart = (productId) => {
    setCart((prevCart) => prevCart.filter((product) => product.id !== productId));
  };


  const handlePaymentMethodChange = (method) => {
    setPaymentMethod(method); // Solo permite seleccionar uno
  };


  const increaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) => {
        if (product.id === productId) {
          if (product.quantity < product.stock_unidades) {
            return { ...product, quantity: product.quantity + 1 };
          } else {
            alert('No puedes agregar más unidades de las disponibles en stock.');
          }
        }
        return product;
      })
    );
  };


  const decreaseQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((product) =>
        product.id === productId && product.quantity > 1
          ? { ...product, quantity: product.quantity - 1 }
          : product
      )
    );
  };

  const calculateTotal = () => {
    const total = cart.reduce((acc, product) => acc + getDiscountedPrice(product) * product.quantity, 0);
    return parseFloat(total.toFixed(2));
  };
  const handleCheckout = async () => {
    setLoading(true);
    setError(null);

    try {
      const payload = {
        productos: cart.map((item) => ({
          id: item.id,
          cantidad: item.quantity,
          precio: getDiscountedPrice(item),
          stock_unidades: item.stock_unidades,
        })),
        total: calculateTotal(),
        mayorista: tipo === 'mayorista' ? selectedWholesalePrice : false, // Condición para mayorista
        detalles_pago: paymentMethod,
      };


      // Realizar la solicitud POST usando axios
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ventas`,
        payload,
        {
          headers: {
            'x-auth-token': token, // Se manda el token en los headers
          }
        }
      );

      console.log(response);

      if (response.status !== 201) {
        throw new Error('Error al realizar la venta');
      }

      setCart([]);
      alert('Venta realizada con éxito');
    } catch (error) {
      setError('Hubo un problema al realizar la venta.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="container mx-auto p-4 max-w-7xl">
      {loading && <p>Cargando...</p>}

      {tipo === 'mayorista' && (
        <div className="mb-6">
          <label className="block text-gray-700 font-bold mb-2">Selecciona el precio mayorista:</label>
          <div className="flex space-x-4">
            <label>
              <input
                type="radio"
                value="precio_mayorista_a"
                checked={selectedWholesalePrice === 'precio_mayorista_a'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista A</span>
            </label>
            <label>
              <input
                type="radio"
                value="precio_mayorista_b"
                checked={selectedWholesalePrice === 'precio_mayorista_b'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista B</span>
            </label>
            <label>
              <input
                type="radio"
                value="precio_mayorista_c"
                checked={selectedWholesalePrice === 'precio_mayorista_c'}
                onChange={handleWholesalePriceChange}
              />
              <span className="ml-2">Mayorista C</span>
            </label>
          </div>
        </div>
      )}

      {/* Buscador de productos */}
      <div className="mb-6 flex justify-center">
        <input
          type="text"
          placeholder="Buscar producto..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleSearchKeyDown}  // Agregar evento onKeyDown para escuchar la tecla Enter

          className="w-full md:w-1/2 p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-6 flex justify-center">
        <select
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
          className="p-3 border border-gray-300 rounded-lg shadow-sm"
        >
          <option value="codigo_barras">Buscar por plug asociado</option>
          <option value="nombre_producto">Buscar por nombre de producto</option>
        </select>
      </div>

      {/* Resultados de la búsqueda */}
      {searchBy === 'nombre_producto' && (
        <div
          className="bg-white shadow-md rounded-lg max-h-64 overflow-y-auto"
        >
          {filteredProducts.length > 0 ? (
            <ul>
              {filteredProducts.map((product) => (
                <li
                  key={product.id}
                  className="py-2 px-4 border-b hover:bg-gray-100 cursor-pointer flex justify-between items-center"
                  onClick={() => addToCart(product)}
                >
                  <span>{product.nombre_producto}</span>
                  <span className="flex flex-col items-end">
                    {product.tieneDescuento ? (
                      <>
                        <span className="text-gray-500 line-through text-sm">
                          ${product.precios[0].precio}
                        </span>
                        <span className="text-green-700 font-bold">
                          ${product.precioFinal}
                        </span>
                      </>
                    ) : (
                      <span className="text-green-700 font-bold">
                        ${getPrice(product)}
                      </span>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="p-4 text-center text-gray-500">No se encontraron productos.</p>
          )}

        </div>
      )}

      {cart.length > 0 && (
        <div className="text-center mb-6 mt-4 sticky bottom-24 z-10 bg-white p-4 rounded-lg shadow-md">
          <div className="mt-4">
            <div className="text-4xl font-semibold text-gray-800">{cart[cart.length - 1].nombre_producto}</div>
            <div className="text-5xl font-bold text-green-700">${getDiscountedPrice(cart[cart.length - 1])}</div>
          </div>
        </div>
      )}

      <h2 className="text-3xl font-bold text-center mb-6">Presupuesto</h2>

      {/* Carrito en formato de presupuesto */}
      {cart.length > 0 ? (
        <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-3 text-left font-semibold text-gray-700">Cantidad</th>
              <th className="p-3 text-left font-semibold text-gray-700">Producto</th>
              <th className="p-3 text-left font-semibold text-gray-700">Precio Unitario</th>
              <th className="p-3 text-left font-semibold text-gray-700">Subtotal</th>
              <th className="p-3  font-semibold text-center text-gray-700">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((item) => (
              <tr key={item.id}>
                <td className="p-3 text-gray-800">{item.quantity}</td>
                <td className="p-3 text-gray-800">{item.nombre_producto}</td>
                <td className="p-3 text-gray-800">${getDiscountedPrice(item)}</td>
                <td className="p-3 text-gray-800">${(getDiscountedPrice(item) * item.quantity).toFixed(2)}</td>
                <td className="p-3 flex gap-2 justify-center">
                  <button
                    onClick={() => decreaseQuantity(item.id)}
                    className="px-4 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600"
                  >
                    -
                  </button>
                  <button
                    onClick={() => increaseQuantity(item.id)}
                    className="px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"
                  >
                    +
                  </button>
                  <button
                    onClick={() => removeFromCart(item.id)}
                    className="px-4 py-2 text-white bg-gray-500 rounded-lg hover:bg-gray-600"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-center text-gray-700 mt-4">No hay productos en el carrito.</p>
      )}

      <div className="sticky bottom-16 z-20 bg-white p-4 rounded-lg shadow-md flex justify-between items-center text-6xl font-semibold text-gray-800">
        <span>Total:</span>
        <span>${calculateTotal()}</span>
      </div>
      <div className="mt-6">
        <label className="block text-gray-700 font-bold mb-4">Método de pago</label>
        <div className="flex flex-row space-x-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'efectivo'}
              onChange={() => handlePaymentMethodChange('efectivo')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Efectivo</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'transferencia'}
              onChange={() => handlePaymentMethodChange('transferencia')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Transferencia</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'debito'}
              onChange={() => handlePaymentMethodChange('debito')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Débito</span>
          </label>
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={paymentMethod === 'credito'}
              onChange={() => handlePaymentMethodChange('credito')}
              className="w-5 h-5 text-blue-500"
            />
            <span>Crédito</span>
          </label>
        </div>
      </div>


      <div className="text-center mt-6">
        <button
          onClick={handleCheckout}
          disabled={loading}
          className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600"
        >
          {loading ? 'Cargando...' : 'Confirmar Venta'}
        </button>
      </div>
    </div>


  );
};

export default CompCarrito;
