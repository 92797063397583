import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { FaBoxOpen, FaHistory } from "react-icons/fa";  // Cambié los iconos a algo más representativo

const CompCajaMenu = () => {

  return (
    <div className="flex-grow flex items-center justify-center mt-16">
      <div className="w-full max-w-4xl p-6">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="space-y-6"
        >
          {/* Título */}
          <div className="flex items-center justify-center mb-6">
            <h2 className="text-primary text-center">
              Caja
            </h2>
          </div>

          {/* Opción: Abrir/Cerrar Caja */}
          <Link
            to="/caja"
            className="block bg-blue-600 rounded-lg py-4 px-6 text-white text-center shadow-md hover:bg-blue-700 transition"
          >
            <div className="flex items-center justify-center">
              <FaBoxOpen className="text-2xl mr-3" />
              <span className="text-lg font-semibold">Abrir/Cerrar Caja</span>
            </div>
          </Link>

          {/* Opción: Historial de Cajas */}
          <Link
            to="/caja/historial"
            className="block bg-green-600 rounded-lg py-4 px-6 text-white text-center shadow-md hover:bg-green-700 transition"
          >
            <div className="flex items-center justify-center">
              <FaHistory className="text-2xl mr-3" />
              <span className="text-lg font-semibold">Historial de Cajas</span>
            </div>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default CompCajaMenu;
