import React from "react";
import { Link } from "react-router-dom";
import {

  FaListAlt,
  FaDollyFlatbed,
  FaDollarSign,
  FaArrowLeft,
} from "react-icons/fa";

const CompVentasMenu = () => {



  return (
    <div className="flex-grow flex items-center justify-center mt-16">
      <div className="w-full max-w-4xl p-6">

        <div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="space-y-4"
        >
          <div className="flex items-center justify-center mb-6">
            <h1 className="text-primary">Ventas</h1>
          </div>
          <Link
            to="/venta/minorista"
            className="block bg-blue-500 rounded-lg p-4 text-white text-center shadow-md hover:bg-blue-600 transition"
          >
            <FaDollarSign className="inline-block text-xl mr-2" />
            Venta por Menor
          </Link>
          <Link
            to="/venta/mayorista"
            className="block bg-purple-500 rounded-lg p-4 text-white text-center shadow-md hover:bg-purple-600 transition"
          >
            <FaDollyFlatbed className="inline-block text-xl mr-2" />
            Venta por Mayor
          </Link>

          <Link
            to="/ventas"
            className="block bg-teal-500 rounded-lg p-4 text-white text-center shadow-md hover:bg-teal-600 transition"
          >
            <FaListAlt className="inline-block text-xl mr-2" />
            Historial
          </Link>
        </div>

      </div>
    </div>
  );
};

export default CompVentasMenu;
