import { Link, useNavigate } from 'react-router-dom'; // Añadido useNavigate para la redirección
import { motion } from 'framer-motion';
import { useSidebar } from './sidebarContext';
import { FaTimes } from 'react-icons/fa';

const CompSidebar = () => {
    const { isSalesOpen, toggleSales, closeSidebar, isSidebarOpen } = useSidebar();
    const navigate = useNavigate(); // Hook para redirección
    const token = localStorage.getItem('token'); // Obtener el token desde localStorage

    const handleLogout = () => {
        // Eliminar el token de localStorage
        localStorage.removeItem('token');
        // Redirigir al login
        navigate('/login');
        // Cerrar el sidebar
        closeSidebar();
    };

    const handleLogin = () => {
        // Redirigir a la página de login
        navigate('/login');
        // Cerrar el sidebar
        closeSidebar();
    };

    return (
        <aside className={`bg-gray-200 text-black h-full mt-8 p-4 w-64 shadow-lg fixed transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} z-50`}>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Zafpan</h1>
                <button onClick={closeSidebar} className="text-black hover:text-red-500 transition">
                    <FaTimes size={24} />
                </button>

            </div>
            <nav>
                <ul>
                    <li className="mb-2">
                        <Link to="/" onClick={closeSidebar} className="block py-2 px-4 hover:bg-white rounded transition">
                            Inicio
                        </Link>
                    </li>
                </ul>

            </nav>
            <div className="mt-4 border-t pt-4">
                <button
                    onClick={token ? handleLogout : handleLogin}
                    className={`block w-full py-2 px-4 ${token ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} text-white rounded transition`}
                >
                    {token ? 'Cerrar Sesión' : 'Iniciar Sesión'}
                </button>
            </div>
        </aside>
    );
};

export default CompSidebar;
