import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPlus, FaTrash } from 'react-icons/fa';
import axios from 'axios';
import CompCreateProv from './createProv';
import { useAuth } from '../context/AuthContext';

const LabelInput = React.memo(({ label, ...props }) => (
  <label className="block mb-4">
    <span className="text-lg font-medium text-gray-700">{label}</span>
    <input
      {...props}
      className="mt-2 w-full px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
    />
  </label>
));

function CompCrearProducto() {
  const { token } = useAuth();  // Obtener el token del contexto
  const [proveedores, setProveedores] = useState([]);
  const [showCreateProvModal, setShowCreateProvModal] = useState(false);
  const [formData, setFormData] = useState({
    nombre_producto: '',
    precio: '',
    precio_compra: '',
    precio_mayorista_a: '',
    precio_mayorista_b: '',
    precio_mayorista_c: '',
    codigo_barras: [''],
    fecha_vencimiento: '',
    stock_unidades: '',
    proveedores_ids: [],
    es_refrigerado: false,
  });


  useEffect(() => {
    const fetchProvs = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/proveedores`,
          {
            headers: {
              'x-auth-token': token,  // Se manda el token en los headers
            },
          }
        );
        setProveedores(response.data.proveedores);
      } catch (error) {
        console.error('Error al obtener los proveedores:', error);
        alert('No se pudieron cargar los proveedores.');
      }
    };
    fetchProvs();
  }, [showCreateProvModal]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleCodigoReferenciaChange = (index, value) => {
    const updatedCodigos = [...formData.codigo_barras];
    updatedCodigos[index] = value;
    setFormData((prev) => ({ ...prev, codigo_barras: updatedCodigos }));
  };

  const addCodigoReferencia = () => {
    setFormData((prev) => ({
      ...prev,
      codigo_barras: [...prev.codigo_barras, ''],
    }));
  };

  const deleteCodigoReferencia = (index) => {
    const updatedCodigos = formData.codigo_barras.filter((_, i) => i !== index);
    setFormData((prev) => ({ ...prev, codigo_barras: updatedCodigos }));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/productos`,
        formData,
        {
          headers: {
            'x-auth-token': token, // Incluye el token en los headers
          },
        }
      );
      setFormData({
        nombre_producto: '',
        precio: '',
        precio_compra: '',
        precio_mayorista_a: '',
        precio_mayorista_b: '',
        precio_mayorista_c: '',
        codigo_barras: [''],
        fecha_vencimiento: '',
        stock_unidades: '',
        proveedores_ids: [],
        es_refrigerado: false,
      });
      alert('Producto creado exitosamente.');  // Alerta de éxito
    } catch (error) {
      console.error('Error al crear el producto:', error);
      alert('Ocurrió un error al crear el producto.');  // Alerta de error
    }
  };

  return (
    <motion.form onSubmit={handleSubmit} className="flex flex-col w-full max-w-2xl items-center justify-center m-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className='text-primary'>Crear Producto</h1>

      <div className="w-full">
        <label className="block mb-4">
          <LabelInput
            required
            label="Nombre:"
            type="text"
            name="nombre_producto"
            value={formData.nombre_producto}
            onChange={handleChange}
          />
        </label>

        <LabelInput
          required
          label="Cantidad de Unidades:"
          type="number"
          name="stock_unidades"
          value={formData.stock_unidades}
          onChange={handleChange}
        />
        <label className="block mb-4">
          <span className="text-lg font-medium text-gray-700">Plug asociado:</span>
          {formData.codigo_barras.map((codigo, index) => (
            <div key={index} className="flex gap-2 mt-2">
              <input
                type="text"
                value={codigo}
                onChange={(e) => handleCodigoReferenciaChange(index, e.target.value)}
                className="flex-1 px-4 py-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
              <button
                type="button"
                onClick={() => deleteCodigoReferencia(index)}
                className="text-red-500 ml-2"
              >
                <FaTrash />
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addCodigoReferencia}
            className="mt-2 inline-flex items-center bg-blue-500 text-white px-3 py-2 rounded-md transition duration-200 hover:bg-blue-600"
          >
            <FaPlus className="mr-2" /> Agregar Plug asociado
          </button>
        </label>

        <LabelInput
          label="Fecha de Vencimiento:"
          type="date"
          name="fecha_vencimiento"
          value={formData.fecha_vencimiento}
          onChange={handleChange}
        />

        <label className="block mb-4">
          <span className="text-lg font-medium text-gray-700">Proveedor:</span>
          <div className="mt-2 space-y-2">
            {proveedores.map((prov) => (
              <label key={prov.id} className="flex items-center">
                <input
                  type="checkbox"
                  value={prov.id}
                  checked={formData.proveedores_ids.includes(prov.id)}
                  onChange={(e) => {
                    const newProveedores = e.target.checked
                      ? [...formData.proveedores_ids, prov.id]
                      : formData.proveedores_ids.filter((id) => id !== prov.id);
                    setFormData((prev) => ({ ...prev, proveedores_ids: newProveedores }));
                  }}
                  className="mr-2"
                />
                <span className="text-gray-700">{prov.nombre}</span>
              </label>
            ))}
          </div>
          <button
            type="button"
            onClick={() => setShowCreateProvModal(true)}
            className="mt-2 inline-flex items-center bg-blue-500 text-white px-3 py-2 rounded-md transition duration-200 hover:bg-blue-600"
          >
            <FaPlus className="mr-2" /> Agregar Proveedor
          </button>
        </label>

        {showCreateProvModal && (
          <CompCreateProv
            onClose={() => setShowCreateProvModal(false)}
            onCreateProveedor={() => {
              setShowCreateProvModal(false); // Cerrar el modal después de crear el proveedor
            }}
          />
        )}
        {/* Grid para precios */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
          <LabelInput
            required
            label="Precio de Venta:"
            type="number"
            name="precio"
            value={formData.precio}
            onChange={handleChange}
            className="border-blue-300" // Color para precio de venta
          />

          <LabelInput
            required
            label="Precio de Compra:"
            type="number"
            name="precio_compra"
            value={formData.precio_compra}
            onChange={handleChange}
            className="border-green-300" // Color para precio de compra
          />

          <LabelInput
            label="Precio Mayorista A:"
            type="number"
            name="precio_mayorista_a"
            value={formData.precio_mayorista_a}
            onChange={handleChange}
          />
          <LabelInput
            label="Precio Mayorista B:"
            type="number"
            name="precio_mayorista_b"
            value={formData.precio_mayorista_b}
            onChange={handleChange}
          />
          <LabelInput
            label="Precio Mayorista C:"
            type="number"
            name="precio_mayorista_c"
            value={formData.precio_mayorista_c}
            onChange={handleChange}
          />
          <label className="flex items-center mb-4">
            <input
              type="checkbox"
              name="es_refrigerado"
              checked={formData.es_refrigerado}
              onChange={handleChange}
              className="mr-2"
            />
            <span className="text-lg font-medium text-gray-700">Refrigerado</span>
          </label>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-3 rounded-md transition duration-300 hover:bg-blue-700"
        >
          Crear Producto
        </button>
      </div>
    </motion.form>
  );
}

export default CompCrearProducto;
