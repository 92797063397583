import React from 'react';
import { useAuth } from '../context/AuthContext.js';
import { motion } from 'framer-motion';
import { AiOutlineHome } from 'react-icons/ai'; // Ícono para el botón
import { useNavigate } from 'react-router-dom';

const PrivateRoute = ({ children, allowedRoles }) => {
    const { userRole, loading } = useAuth();
    const navigate = useNavigate();

    if (loading) return <div>Loading...</div>;

    if (!userRole || !allowedRoles.includes(userRole)) {
        return (
            <motion.div
                className="flex flex-col items-center justify-center h-screen bg-gray-100"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
            >
                <div className="bg-white shadow-lg rounded-lg p-8 max-w-md text-center">
                    <h2 className="text-2xl font-bold text-red-500 mb-4">
                        No tienes permisos para acceder
                    </h2>
                    <p className="text-gray-700 mb-6">
                        Parece que no tienes los permisos necesarios para ver esta página.
                        Si crees que esto es un error, contacta al administrador. Tu rol actual es: {userRole}
                    </p>
                    <div className="flex justify-center">
                        <button
                            onClick={() => navigate('/')}
                            className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 transition"
                        >
                            <AiOutlineHome size={20} />
                            Volver al Inicio
                        </button>
                    </div>
                </div>
            </motion.div>
        );
    }

    return children;
};

export default PrivateRoute;
